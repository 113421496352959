import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPage = ({ location, data }) => {
  const posts = data.allMarkdownRemark.nodes

  console.log(posts)

  return (
    <Layout title={'Blog Posts'} location={location}>
      <Seo title="Blog Page" description="Here is a list of articles where I give insight on certain monetization topics" />

      <ol style={{ listStyle: `none` }} className="lg:w-4/6 lg:mx-auto p-4">
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug

          return (
            <li key={post.fields.slug} className="border-b-4 py-4 my-3">
              <article
                className="post-list-item text-slate-700 dark:text-slate-50"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link className="dark:text-slate-50 text-slate-700 text-2xl font-sans underline dark:decoration-gray-300 decoration-purple-900" to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small className="dark:text-slate-50 text-slate-700">{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    className="text-slate-600 dark:text-sky-200 font-serif"
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          )
        })}
      </ol>

    </Layout>
  )
}

export default BlogPage


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }  
`